<template>
  <div class="story">
    <!-- 团队总介绍 -->
    <div class="story-content">
      <div
        class="sc-box"
        @click="goDetail(item)"
        v-for="(item, index) in storyDetailList"
        :key="index"
      >
        <!-- 标题 -->
        <div class="sc-text">{{ item.title }}</div>
        <!-- 发布时间 -->
        <!-- <div class="sc-time">
          <div class="sc-time-box">
            <div class="pt-day">
              {{ item.createTime.slice(8, 10) }}
            </div>
            <div class="pt-year">
              {{ item.createTime.slice(0, 7) }}
            </div>
          </div>
        </div> -->
        <div class="sc-img">
          <el-image
            style="width: 250px; height: 150px; display: block"
            :src="item.img"
            fit="cover"
          ></el-image>
        </div>
        <!-- 时间 -->
        <div class="sc-createTime">{{ item.createTime }}</div>

        <!-- 详情 -->
        <div class="sc-content">{{ item.jsonText }}</div>
      </div>
      <!-- 分页 -->
      <div class="it-pagination">
        <el-pagination
          background
          layout=" prev, pager, next"
          :total="pageCount"
          :page-size="6"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
    <!-- 导航 -->
    <!-- <div class="story-nav">
      <div class="eq-theme">{{ name }}</div>
      <div
        class="eq-tit"
        v-for="(item, index) in storyList"
        :key="index"
        :class="{ activeClass: currentIndex === index }"
        @click="changeActive(index, item)"
      >
        <i class="el-icon-arrow-right"></i>{{ item.name }}
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      // name: "",
      // storyList: [],
      currentIndex: 0,
      page: 1,
      pageDataList: [], //内容列表(分页前)
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // this.name = res.data.data.name;
            // this.storyList = res.data.data.titles;
            this.pageDataList = res.data.data.pageDatas;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    //点击分页
    changePage(page) {
      this.page = page;
    },
    // changeActive(index, item) {
    //   this.pageDataList = item.pageDatas;
    //   this.currentIndex = index;
    // },
    goDetail(item) {
      // this.$router.push({
      //   name: "newsDetail",
      //   query: {
      //     pageDataId: item.pageDataId,
      //     titleId: this.$route.query.titleId,
      //     demoTitleId: this.$route.query.demoTitleId,
      //   },
      // });
    },
  },
  computed: {
    // 初始化分页
    pageCount() {
      return this.pageDataList.length;
    },
    //默认显示第一个三级标题的内容
    storyDetailList() {
      // 分页
      //开始下标： (当前页数-1)*每页条数
      //结束下标： (开始下标+每页条数)-1
      let limit = 6;
      let startIndex = (this.page - 1) * limit;
      let endIndex = startIndex + limit - 1;
      return this.pageDataList.slice(startIndex, endIndex + 1);
    },
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.story {
  width: 100%;
  display: flex;
  .story-nav {
    width: 300px;
    margin-left: 50px;
    .eq-theme {
      color: rgb(255, 255, 255);
    }
    .eq-theme,
    .eq-tit {
      height: 50px;
      background-color: #d80930;
      color: rgb(255, 255, 255);
      line-height: 50px;
      font-size: 18px;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .eq-tit {
      cursor: pointer;
      font-size: 15px;
      background-color: #f2f2f2;
      color: #4d4d4d;
      border-bottom: 1px solid #ffffff;
    }
    .activeClass {
      background-color: #dfdfdf;
    }
  }
  .story-content {
    width: 100%;
    .sc-box {
      cursor: pointer;
      padding: 50px 0 50px 300px;
      box-sizing: border-box;
      border-bottom: 1px solid #ececec;
      position: relative;
      .sc-text {
        display: block;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 18px;
        color: #171616;
      }
      .sc-time {
        height: 80px;
        width: 100px;
        position: absolute;
        top: 30px;
        left: 0;
        border: 3px solid #eaebef;
        color: #303032;
        text-align: center;
        font-family: arial;
        display: flex;
        justify-content: center;
        align-items: center;
        .pt-day {
          font-size: 36px;
        }
        .pt-year {
          font-size: 12px;
        }
      }
      .sc-img {
        position: absolute;
        top: 35px;
        left: 0;
      }
      .sc-createTime {
        font-size: 13px;
        color: #797979;
        margin-bottom: 10px;
      }

      .sc-content {
        font-size: 14px;
        color: #797979;
        line-height: 24px;
        height: 48px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .more {
        width: 90px;
        height: 30px;
        border: 1px solid rgb(231, 231, 231);
        color: rgb(155, 155, 155);
        line-height: 30px;
        text-align: center;
        background-color: rgb(255, 255, 255);
        margin-top: 55px;
      }
    }
    .it-pagination {
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        color: rgb(255, 255, 255);
        background: #d80930;
      }
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: rgb(255, 255, 255);
        background: #d80930;
      }
    }
  }
}
</style>
